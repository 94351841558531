<template>
  <de-dialog class="sm:tw-w-[525px]">
    <template #header>
      <h1 class="heading-h2 tw-mb-1.5">
        {{ $t('common.submitDialog.title') }}
      </h1>
      <p class="body-b1 tw-text-primary-300">
        {{ $t('common.submitDialog.subtitle') }}
      </p>
    </template>

    <ul class="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
      <li v-for="item in submitDialogButtonsConfig" :key="item.id" class="tw-w-full">
        <component
          :is="listItemComponent"
          class="tw-flex sm:tw-flex-col tw-items-center sm:tw-justify-center tw-gap-3 tw-w-full tw-bg-primary-700 hover:tw-bg-primary-600 tw-transition-all tw-p-6 tw-cursor-pointer"
          :to="item.href"
          @click="isLoggedIn ? undefined : onSubmitBtnClick()"
        >
          <de-svg-icon :name="item.icon" class="tw-w-6 tw-h-6 tw-text-primary-400" />
          <span class="tw-leading-500">{{ $t(item.label) }}</span>
        </component>
      </li>
    </ul>
  </de-dialog>
</template>

<script setup lang="ts">
import { computed, resolveComponent } from 'vue';
import DeDialog from '~/shared/components/lib/dialog/DeDialog.vue';
import { submitDialogButtonsConfig } from '~/layouts/parts/header/submit-dialog.config';
import DeSvgIcon from '~/shared/components/lib/svg-icon/DeSvgIcon.vue';
import { useAppStore } from '~/store/app';
import { AuthTab } from '~/data/domain/auth.entity';

const isLoggedIn = false;

const listItemComponent = computed(() => {
  if (isLoggedIn) return resolveComponent('nuxt-link-locale');

  return 'button';
});

const store = useAppStore();

const onSubmitBtnClick = () => {
  store.toggleAuthDialog(AuthTab.login, true);
};
</script>
